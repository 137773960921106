import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import CreateClassIcon from '../../../assets/icons/add-pc.svg';
import StyledLink from './StyledLink';

const CreateClass = styled(StyledLink)`
  background-color: ${$.color.green};

  &:hover {
    color: ${$.color.green};
    border: 2px solid ${$.color.green};
    > svg {
      fill: ${$.color.green};
    }
  }
`;

const text = 'Create class';

const CreateClassButton = ({ className }) => {
  return (
    <CreateClass to="/instructor/create/" title={text} className={className}>
      <CreateClassIcon />
      <div>{text}</div>
    </CreateClass>
  );
};

CreateClassButton.defaultProps = {
  className: '',
};

CreateClassButton.propTypes = {
  className: PropTypes.string,
};

export default CreateClassButton;
