import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useLazyQuery } from '@apollo/react-hooks';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import SleepIcon from '../../../../assets/icons/sleep.svg';
import $ from '../../../../styles/global';
import { restartInstanceGQL } from '../../../../apollo/instructor';
import useActions from '../../../../utils/useActions';
import { openModal } from '../../../../actions';

const Container = styled.div`
  ${({ color }) => `
    border-radius: 999px 0 0 999px;

    &.selected,
    &:hover {
      > * {
        background-color: ${color};
        border: 3px solid ${$.color.gray2};
        color: ${$.color.white};
        cursor: pointer;
      }
      svg {
        fill: ${$.color.white};
      }
    }
  `}
`;

const Circle = styled.div`
  ${({ color }) => `
    border-radius: 999px;
    background-color: ${$.color.white};
    transition: all 0.3s ${$.easingFn.standard};
    color: ${color};
    border: 3px solid ${color};
    user-select: none;
    position: relative;
  `}
`;

const Name = styled.div`
  text-align: center;
  line-height: 1em;
  font-size: 17px;
  padding: 15px 10px;
  font-family: Dosis Bold;
`;

const StyledSleepIcon = styled(SleepIcon)`
  ${({ color }) => `
  width: 40px;
  height: 30px;
  fill: ${color};
  display: block;
  margin: 8px auto;
  transition: all 0.3s ${$.easingFn.standard};
`}
`;

/**
 * Returns the initial of the given name to form Google Doc-like user buttons.
 * @param {string} name
 * @returns {string} Given name's initial.
 */
const getName = name => {
  const splitName = name.split(' ');

  if (splitName.length > 0) {
    return splitName
      .map(str => {
        return str[0];
      })
      .join('');
  }

  return name[0];
};

const VMCircle = ({
  name,
  instructor,
  selected,
  handleOnClick,
  focused,
  id,
}) => {
  /**
   * useRef will mutate the colours every time the component remounts, so
   * we need to use useState to ensure that the colour is not randomized again
   * when the component remounts.
   */
  const [color, setColor] = useState($.color.white);
  const [showConfirmationModal] = useActions([openModal]);
  const [restartInstance] = useLazyQuery(restartInstanceGQL, {
    variables: {
      userId: id,
    },
  });

  useEffect(() => {
    setColor(() => {
      /**
       * Simply returns a really dark colour. This won't return bright colours.
       */
      const letters = '0123456789'.split('');
      let hex = '#';
      let i = 0;

      for (; i < 6; i += 1) {
        hex += letters[Math.floor(Math.random() * 10)];
      }

      return hex;
    });
  }, []);

  return (
    <>
      <ReactTooltip />
      <ContextMenuTrigger id={id}>
        <Container
          data-tip={
            instructor ? `${name} (You)` : name + (focused ? '' : ' (Inactive)')
          }
          className={selected ? 'selected' : ''}
          color={instructor ? $.color.blue5 : color}
          onClick={handleOnClick}
        >
          <Circle
            instructor={instructor}
            color={instructor ? $.color.blue5 : color}
          >
            {focused ? (
              <Name>{instructor ? 'You' : getName(name)}</Name>
            ) : (
              <StyledSleepIcon color={color} />
            )}
          </Circle>
        </Container>
      </ContextMenuTrigger>
      <ContextMenu id={id}>
        <MenuItem
          onClick={() => {
            showConfirmationModal({
              title: 'Reboot instance confirmation',
              components: (
                <div>
                  {`It takes approximately 30 secs to 1 minute to reboot an instance. Do you want to reboot ${
                    instructor ? 'your own' : `${name}'s`
                  } instance?`}
                </div>
              ),
              buttons: [
                {
                  text: 'Cancel',
                  color: $.color.gray2,
                },
                {
                  text: 'Yes',
                  color: $.color.green,
                  callback: () => {
                    restartInstance();
                  },
                },
              ],
            });
          }}
        >
          Reboot instance
        </MenuItem>
      </ContextMenu>
    </>
  );
};

VMCircle.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.bool,
  name: PropTypes.string,
  handleOnClick: PropTypes.func,
  instructor: PropTypes.bool,
  focused: PropTypes.bool,
};

VMCircle.defaultProps = {
  id: '',
  selected: false,
  focused: true,
  handleOnClick: () => {},
  name: '',
  instructor: false,
};

export default VMCircle;
