import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import defaultSettings from '../../VNC/defaultSettings.json';
import VMList from './VMList';
import useActions from '../../../utils/useActions';
import Utils from '../../../utils';
import { setUser } from '../../../actions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${$.color.white};
  height: 100%;
  overflow: hidden;
`;

const InstructorViewContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${$.color.gray1};
  position: relative;

  &.fullscreen {
    position: initial;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

/**
 * Ensures that VNC is loaded only when the component here has finished mounting.
 * Also prevents VNC from being parsed during `gatsby build`.
 */
const LoadableVNC = loadable(() => import('../../VNC'));

const VMClass = () => {
  const [noVNCInstance, setNoVNCInstance] = useState(null);
  /**
   * This option list will largely remain untouched saved for the password, host and port.
   * @param {string} password - Password to access the noVNC server.
   * @param {string} host - IP address of the noVNC server.
   * @param {number} port - Port number of the noVNC server.
   * @param {boolean} autoconnect - Connects to server automatically when page reloads.
   * @param {boolean} encrypt - True for wss. False for ws.
   * @param {boolean} viewOnly - Turns off user interaction with the noVNC if true. False if otherwise.
   * @param {boolean} reconnect - Attempts to reconnect automatically if disconnected.
   * @param {boolean} viewClip - True to allow scaling according to parent container. False if otherwise.
   * @param {string} resize - 'off' = no resizing.
   * 'remote' = force noVNC server to resize based on client window.
   * 'scale' = force noVNC client to resize locally using CSS.
   */
  const [vncOptions, setVNCOptions] = useState(defaultSettings);
  const [setCurrentUser] = useActions([setUser]);
  const state = useSelector(({ user, instructorSession }) => {
    return { user, instructorSession };
  });

  /**
   * Sets up the class once the component has mounted and user profile is pulled.
   */
  useEffect(() => {
    state.instructorSession.wsInstance.start();
    setCurrentUser({ inClass: true });
  }, []);

  /**
   * Listens to instructor's selection, whether to show the selected VM or selected vid.
   */
  useEffect(() => {
    const active = state.instructorSession.activeView;

    setVNCOptions(prev => {
      const newPrev = { ...prev };

      // 1. Check if connection are different from the current one before swapping.
      // 2. Update to the new values, then swap the noVNC instance with new values.
      // 3. Update state with new connection.
      if (newPrev.host !== Utils.getHostAndPort(active).host && active) {
        newPrev.host = Utils.getHostAndPort(active).host;
        newPrev.port = Utils.getHostAndPort(active).port;

        if (noVNCInstance) {
          noVNCInstance.swap(newPrev);
        }
      }

      return newPrev;
    });
  }, [state.instructorSession.activeView]);

  return (
    <Container>
      <VMList />
      <InstructorViewContainer>
        {vncOptions.host && vncOptions.port ? (
          <LoadableVNC
            options={vncOptions}
            noVNCInstance={noVNCInstance}
            setNoVNCInstance={setNoVNCInstance}
          />
        ) : (
          ''
        )}
      </InstructorViewContainer>
    </Container>
  );
};

export default VMClass;
