import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';

import $ from '../../../../styles/global';
import VMCircle from './VMCircle';
import { setActiveView } from '../../../../actions';
import useActions from '../../../../utils/useActions';

const Container = styled.div`
  position: relative;
  border-right: 1px solid ${$.color.gray1};
  height: 100%;

  > * {
    padding: ${$.layout().padding2}px;
  }
`;

const InnerContainer = styled.div`
  height: calc(80% - ${$.layout().padding2 * 2}px);
  overflow: auto;

  > * {
    margin-bottom: ${$.layout().margin2}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const VersionNumber = styled.div`
  font-size: 17px;
  color: ${$.color.blue4};
  text-align: center;
  position: absolute;
  width: calc(100% - ${$.layout().padding2 * 2}px);
  bottom: ${$.layout().padding2}px;
`;

/**
 * Shows a list of VM IP address along with the student names.
 * As for instructor, it's always the first option + name will show as "You".
 */
const VMList = () => {
  const {
    site: {
      siteMetadata: { version },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);
  const state = useSelector(({ instructorSession, user }) => {
    return { instructorSession, user };
  });
  const [activeVM, setActiveVM] = useState({ id: state.user.id });
  const [setView] = useActions([setActiveView]);
  const [focused, setFocused] = useState({});

  useEffect(() => {
    const cb = e => {
      setFocused(prev => {
        const newPrev = { ...prev, ...e.detail.vm };

        return newPrev;
      });
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('focused', cb);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('focused', cb);
      }
    };
  }, []);

  return (
    <Container>
      <InnerContainer>
        {state.instructorSession.instructorVM && (
          <VMCircle
            name={state.user.name}
            instructor
            id={state.user.id}
            handleOnClick={() => {
              setView(state.instructorSession.instructorVM);

              setActiveVM({
                ipAddress: state.instructorSession.instructorVM,
                id: state.user.id,
              });

              if (state.instructorSession.swapState) {
                state.instructorSession.wsInstance.swapTo(
                  state.instructorSession.instructorVM
                );
              }
            }}
            selected={activeVM && activeVM.id === state.user.id}
          />
        )}
        {state.instructorSession.students.map(
          ({ ipAddress, name, studentId }) => {
            return (
              <VMCircle
                id={studentId}
                key={studentId}
                name={name}
                focused={focused[ipAddress]}
                selected={activeVM && activeVM.id === studentId}
                handleOnClick={() => {
                  setView(ipAddress);

                  if (state.instructorSession.swapState) {
                    state.instructorSession.wsInstance.swapTo(ipAddress);
                  }

                  setActiveVM({
                    ipAddress,
                    id: studentId,
                    name,
                  });
                }}
              />
            );
          }
        )}
      </InnerContainer>
      <VersionNumber data-tip={`Current version: v${version}`}>
        {`v${version}`}
      </VersionNumber>
    </Container>
  );
};

export default VMList;
