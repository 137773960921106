import { Link } from 'gatsby';
import styled from 'styled-components';

import $ from '../../../styles/global';

const StyledLink = styled(Link)`
  border-radius: ${$.border().radius2}px;
  text-align: center;
  padding: ${$.layout().padding2}px;
  text-decoration: none;
  border: 2px solid transparent;
  font-size: 18px;
  font-family: Ruda Bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ${$.easingFn.standard};
  color: ${$.color.white};
  width: 165px;

  &:visited {
    color: ${$.color.white};
  }
  > svg {
    fill: ${$.color.white};
    width: 50px;
    height: 50px;
    transition: all 0.3s ${$.easingFn.standard};
    margin-bottom: ${$.layout().margin2}px;
  }

  &:hover {
    background-color: ${$.color.white};
  }
`;

export default StyledLink;
