import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import $ from '../../styles/global';
import WS from '../../utils/WS';
import SEO from '../../components/seo';
import VMClass from '../../components/instructor/VMClass';
import { getClassGQL } from '../../apollo/instructor';
import Loading from '../../components/Loading';
import TYPES from '../../actions/types';
import Utils from '../../utils';
import useActions from '../../utils/useActions';
import { setInstructorSession } from '../../actions';
import CreateClassButton from '../../components/instructor/Dashboard/CreateClassButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Message = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1.3em;
`;

const ErrMessage = styled(Message)`
  color: ${$.color.red};
`;

const StyledCreateClassButton = styled(CreateClassButton)`
  margin-top: ${$.layout().margin3}px;
  margin-left: auto;
  margin-right: auto;
`;

const ClassPage = () => {
  const [hasClass, setHasClass] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const state = useSelector(({ user, instructorSession }) => {
    return { user, instructorSession };
  });
  const [setCurrentSession] = useActions([setInstructorSession]);
  const [getClass] = useLazyQuery(getClassGQL, {
    variables: { instructorId: state.user.id },
    onCompleted: ({ instructor }) => {
      setLoading(false);

      if (instructor) {
        setCurrentSession({
          wsInstance: new WS(TYPES.INSTRUCTOR_ROLE, instructor.twilioRoomName),
          instructorVM: instructor.ipAddress,
          twilioRoomName: instructor.twilioRoomName,
          students: instructor.enlistedStudents,
        });
        setHasClass(true);
      }
    },
    onError: ({ message }) => {
      setLoading(false);
      setError(message);
    },
  });

  /**
   * Entry point. We kick out anyone who is not an instructor before we begin requesting
   * for a class session.
   */
  useEffect(() => {
    Utils.redirect(state.user.role);

    if (state.instructorSession.instructorVM) {
      setHasClass(true);
      setLoading(false);
    } else {
      getClass();
    }
  }, []);

  return (
    <>
      <SEO
        title="Class | SG Code Campus Online Platform App"
        description="Learn to code in SG Code Campus's very own online learning platform."
      />
      {loading && (
        <Container>
          <Loading />
        </Container>
      )}
      {error && (
        <Container>
          <ErrMessage>{`An error occurred: ${error}`}</ErrMessage>
        </Container>
      )}
      {hasClass ? (
        <VMClass />
      ) : (
        <Container>
          <Message>
            <div>
              No ongoing class found.
              <br />
              Please create a class first.
            </div>
            <StyledCreateClassButton />
          </Message>
        </Container>
      )}
    </>
  );
};

export default ClassPage;
